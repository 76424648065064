/* ==========================
    Sidebar Header 
=============================*/

.br_header-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100px;
  height: 100%;

  @media #{$lg-layout} {
    position: static;
    width: 100%;
    height: auto;
  }

  @media #{$md-layout} {
    position: static;
    width: 100%;
    height: auto;
  }

  @media #{$sm-layout} {
    position: static;
    width: 100%;
    height: auto;
  }

  .side-header-inner {
    height: 100%;
    border: 0;
    border-right-width: 0px;
    border-color: rgba(0, 0, 0, 0);
    background: #fff;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    -webkit-box-shadow: 0 -9px 25px rgba(0, 0, 0, .03);
    -moz-box-shadow: 0 -9px 25px rgba(0, 0, 0, .03);
    box-shadow: 0 -9px 25px rgba(0, 0, 0, .03);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    @media #{$lg-layout} {
      height: auto;
    }

    @media #{$md-layout} {
      height: auto;
    }

    @media #{$sm-layout} {
      height: auto;
    }


    .header-wrap {
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-flow: column;
      -ms-flex-flow: column;
      flex-flow: column;
      padding: 15px 10px 50px;

      @media #{$lg-layout} {
        height: auto;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row;
        -ms-flex-flow: row;
        flex-flow: row;
        padding: 24px 23px 26px;
        width: 100%;
        align-items: center;
      }

      @media #{$md-layout} {
        height: auto;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row;
        -ms-flex-flow: row;
        flex-flow: row;
        padding: 24px 23px 26px;
        width: 100%;
        align-items: center;
      }

      @media #{$sm-layout} {
        height: auto;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row;
        -ms-flex-flow: row;
        flex-flow: row;
        padding: 24px 23px 26px;
        width: 100%;
        align-items: center;
      }


      // Header Top
      .header-top {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;

        .branding {
          a {
            img {

            }
          }
        }

        .popup-open-menu {
          margin-top: 36px;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          height: 30px;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          cursor: pointer;
          color: #999;

          @media #{$lg-layout} {
            display: none;
          }

          @media #{$md-layout} {
            display: none;
          }

          @media #{$sm-layout} {
            display: none;
          }

          .menu-icon {
            position: relative;
            width: 24px;
            height: 18px;

            i {
              color: #999;
              position: absolute;
              top: 50%;
              left: 0;
              margin-top: -1px;
              width: 100%;
              height: 2px;
              background: currentColor;
              -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
              transition: all .3s cubic-bezier(.645, .045, .355, 1);
              content: "";

              &::before,
              &::after {
                position: absolute;
                left: 50%;
                width: 100%;
                height: 100%;
                background: inherit;
                content: "";
                -webkit-transition: width .3s cubic-bezier(.645, .045, .355, 1);
                transition: width .3s cubic-bezier(.645, .045, .355, 1);
                -webkit-transform: translate(-50%);
                -ms-transform: translate(-50%);
                transform: translate(-50%);
              }

              &::before {
                top: -8px;
              }

              &::after {
                top: 8px;
              }

            }

            &:hover {
              i {
                color: #000000;

                &::before,
                &::after {
                  width: 70%;
                }
              }
            }

          }
        }

      }


      // Header Center
      .header-center {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        position: relative;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;

        // Header Social Network
        .header-social-networks {
          -webkit-writing-mode: vertical-rl;
          -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;

          @media #{$lg-layout} {
            writing-mode: horizontal-tb;
          }

          @media #{$md-layout} {
            writing-mode: horizontal-tb;
          }

          @media #{$sm-layout} {
            writing-mode: horizontal-tb;
          }

          @media #{$large-mobile} {
            display: none;
          }

          .inner {
            margin: -5px -15px;

            a {
              display: inline-block;
              margin: 5px 15px;
              padding: 5px 0;
              font-size: 14px;
              font-family: var(--ff-secondary);

              span {
                color: #999;
                -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
                transition: all .3s cubic-bezier(.645, .045, .355, 1);
              }

              &:hover {
                span {
                  color: #000000;
                }
              }
            }
          }
        }


      }

      // Header Bottom
      .header-bottom {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;

        @media #{$lg-layout} {
          display: flex;
        }

        @media #{$md-layout} {
          display: flex;
        }

        @media #{$sm-layout} {
          display: flex;
        }

        .btn-search-click {
          display: block;
          line-height: 0;
          font-size: 18px;
          color: #999999;
          text-align: center;

          i {

          }

          &:hover {
            color: #000000;
          }
        }
      }
    }
  }
}
































